import React from 'react';
import PropTypes from 'prop-types';

const InputWithLabel = ({ error, errorMessage, name, label, children, className, labelSize }) => {
  return (
    <label htmlFor={name} className={className}>
      <div className='app-form-wrapper'>
        {error ? (
          <p
            id={`${name}-error`}
            className={labelSize === 'large' ? 'app-input-error-large' : 'app-input-error'}
            data-testid={`${name}-error`}
          >
            {errorMessage}
          </p>
        ) : (
          <span
            data-testid={`${name}_label`}
            className={labelSize === 'large' ? 'app-form-label-large' : 'app-form-label'}
          >
            {label}
          </span>
        )}
      </div>
      {children}
    </label>
  );
};

InputWithLabel.propTypes = {
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  labelSize: PropTypes.string
};

InputWithLabel.defaultProps = {
  error: undefined,
  children: null,
  errorMessage: '',
  // TODO: @ankit remmove className
  className: 'col-span-6 lg:col-span-2 relative block',
  labelSize: 'regular'
};

export default InputWithLabel;
